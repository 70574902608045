<template>
  <v-form ref="addManual" lazy-validation>
    <v-row justify="center">
      <v-dialog
        v-model="open"
        scrollable
        transition="scale-transition"
        persistent
        max-width="900px"
      >
        <v-card outlined>
          <v-card-title class="title font-weight-bold">
            {{
              isEdit
                ? $t("e_test.update_question")
                : $t("e_test.added_question")
            }}
            <div style="position: absolute; right: 25px;">
              <v-icon :disabled="disableButton" @click="toggle(false)">
                mdi-close
              </v-icon>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="pt-4">
              <v-col cols="12" sm="8" class="d-flex" offset="4">
                <v-autocomplete
                  v-model="bodyRequest.periode"
                  :items="generateYears()"
                  :label="$t('app.select') + ' ' + 'Periode'"
                  :rules="[v => !!v || $t('app.required')]"
                  :disabled="disableButton"
                  item-text="name"
                  item-value="id"
                  type="number"
                  outlined
                  dense
                  class="mr-2 d-inline-block select-200"
                  id="periode"
                />
                <v-select
                  v-if="isGlobal"
                  v-model="bodyRequest.grade"
                  :label="$t('app.select') + ' ' + $t('app.grade')"
                  :rules="[v => !!v || $t('app.required')]"
                  :items="listGrade"
                  :disabled="disableButton"
                  :loading="loadingGrade"
                  item-text="grade"
                  item-value="id"
                  outlined
                  dense
                  class="d-inline-block"
                  id="grade"
                />
                <v-select
                  v-else
                  v-model="bodyRequest.class"
                  :items="dataClass"
                  :label="$t('app.select') + $t('app.class')"
                  :rules="[v => !!v || $t('app.required')]"
                  :disabled="disableButton"
                  :loading="loadingClass"
                  item-value="id"
                  item-text="name"
                  multiple
                  outlined
                  dense
                  class="d-inline-block"
                  id="class"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">
                      {{ `${item.name}` }}
                    </span>
                    <span v-if="index === 1" class="grey--text">
                      ( +{{ bodyRequest.class.length - 1 }}
                      {{ $t("app.others") }} )
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" offset="4">
                <v-select
                  v-if="isGlobal"
                  v-model="bodyRequest.lesson"
                  :label="$t('app.select') + ' ' + $t('app.subjects')"
                  :rules="[v => !!v || $t('app.required')]"
                  :items="subjectsList"
                  :disabled="
                    disableButton || subjectsLoading || !bodyRequest.grade
                      ? true
                      : false
                  "
                  :loading="subjectsLoading"
                  item-text="lesson_name"
                  item-value="lesson"
                  outlined
                  dense
                  id="lesson"
                />
              </v-col>
              <v-col cols="12">
                <v-divider class="mb-3" />
              </v-col>
              <v-col cols="12" sm="4" class="subtitle-1 font-weight-bold">
                {{ $t("e_test.question_type") }}
              </v-col>
              <v-col cols="12" sm="8">
                <v-radio-group v-model="bodyRequest.type" row>
                  <v-radio
                    v-for="item in typeAnswer"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="subtitle-1 font-weight-bold">
                {{ $t("e_test.question") }}
              </v-col>
              <v-col cols="12" sm="8" id="question">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="bodyRequest.question"
                  :disabled="disableButton"
                  :options="editorOption"
                />
                <!-- start upload file for question -->
                <ButtonUpload
                  :label="$t('e_test.upload_document')"
                  :accept="extDocument"
                  :loading="questionFile.loading"
                  :disabled="bodyRequest.question_file.length >= 3"
                  class="my-2"
                  multiple
                  depressed
                  @getDocument="
                    fileData => {
                      this.questionFile.tempFile = fileData;
                      this.uploadQuestion('question');
                    }
                  "
                />
                <v-row no-gutters>
                  <v-col
                    v-for="(file, index) in bodyRequest.question_file"
                    :key="index"
                    cols="12"
                    sm="4"
                    class="text-center"
                  >
                    <v-chip
                      close
                      color="primary"
                      @click="openLink(file)"
                      @click:close="
                        removeFiles(bodyRequest.question_file, file)
                      "
                    >
                      {{ file.replace(/^.*[\\\/]/, "") }}
                    </v-chip>
                  </v-col>
                </v-row>
                <!-- end upload file for question -->
              </v-col>
              <v-col cols="12">
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="subtitle-1 font-weight-bold">
                {{ $t("e_test.answer") }}
                <v-tooltip v-if="bodyRequest.type === 'pg'" max-width="200" top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>mdi-information</v-icon>
                  </template>
                  <span>{{ $t("e_test.info_answer") }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="8">
                <div v-if="bodyRequest.type === 'esay'" id="answerEssay">
                  <quill-editor
                    ref="editorEsay"
                    v-model="bodyRequest.esay.key"
                    :disabled="disableButton"
                    :options="editorOption"
                  />
                  <!-- start upload file for answer essay -->
                  <ButtonUpload
                    :label="$t('e_test.upload_document')"
                    :accept="extDocument"
                    :loading="essayFile.loading"
                    :disabled="bodyRequest.esay.key_file.length >= 3"
                    class="my-2"
                    multiple
                    depressed
                    @getDocument="
                      fileData => {
                        this.essayFile.tempFile = fileData;
                        this.uploadQuestion('essay');
                      }
                    "
                  />
                  <v-row no-gutters>
                    <v-col
                      v-for="(file, index) in bodyRequest.esay.key_file"
                      :key="index"
                      cols="12"
                      sm="4"
                      class="text-center"
                    >
                      <v-chip
                        close
                        color="primary"
                        @click="openLink(file)"
                        @click:close="
                          removeFiles(bodyRequest.esay.key_file, file)
                        "
                      >
                        {{ file.replace(/^.*[\\\/]/, "") }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <!-- end upload file for answer essay -->
                </div>
                <v-row
                  v-else
                  v-for="(item, index) in bodyRequest.pg"
                  :key="item.code"
                >
                  <v-col
                    cols="12"
                    class="d-flex flex-row mb-3 align-baseline"
                    id="answerPg"
                  >
                    <div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-checkbox
                              v-model="item.key"
                              :disabled="disableButton"
                              :label="item.code"
                              hide-details
                              class="mx-2 pa-0 font-weight-bold"
                            />
                          </span>
                        </template>
                        <span>{{ $t("e_test.mark_correct") }}</span>
                      </v-tooltip>
                    </div>
                    <div>
                      <quill-editor
                        ref="editorPG"
                        v-model="item.answer"
                        :key="item.code"
                        :disabled="disableButton"
                        :options="editorOption"
                        class="mb-3"
                      />
                      <!-- start upload file for answer pg -->
                      <ButtonUpload
                        :label="$t('e_test.upload_document')"
                        :accept="extDocument"
                        :loading="item.loading"
                        :disabled="item.answer_file.length >= 3"
                        class="my-2"
                        multiple
                        depressed
                        @getDocument="
                          fileData => {
                            item.tempFile = fileData;
                            uploadQuestion('pg', { item: item, index: index });
                          }
                        "
                      />
                      <v-row no-gutters>
                        <v-col
                          v-for="(file, i) in bodyRequest.pg[index].answer_file"
                          :key="i"
                          cols="12"
                          sm="5"
                          class="text-center my-2"
                        >
                          <v-chip
                            close
                            color="primary"
                            @click="openLink(file)"
                            @click:close="
                              removeFiles(
                                bodyRequest.pg[index].answer_file,
                                file
                              )
                            "
                          >
                            {{ file.replace(/^.*[\\\/]/, "") }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <!-- end upload file for answer pg -->
                    </div>
                    <div
                      v-if="index !== 0 && index !== 1"
                      class="justify-center d-flex ml-2"
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="error"
                            icon
                            dark
                            depressed
                            @click="deleteAnswer(index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("e_test.delete_answer") }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="mb-5" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div
                  v-if="
                    bodyRequest.pg.length <= 10 && bodyRequest.type === 'pg'
                  "
                  class="justify-end d-flex"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="primary"
                        depressed
                        @click="addedAnswer()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("e_test.added_answer") }}</span>
                  </v-tooltip>
                </div>
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="font-weight-bold">
                {{ $t("e_test.basic_competencies") }}
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  v-model="bodyRequest.kd"
                  :disabled="
                    disableButton || (!bodyRequest.lesson && isGlobal)
                      ? true
                      : false
                  "
                  :rules="[v => !!v || $t('app.required')]"
                  :items="dataKD"
                  :label="
                    $t('app.select') + ' ' + $t('e_test.basic_competencies')
                  "
                  :loading="loadingKD"
                  :item-text="
                    item => `${item.core_competencies} - ${item.kd_chapter}`
                  "
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="font-weight-bold">
                {{ $t("e_test.weight") }}
              </v-col>
              <v-col cols="12" sm="8" id="weight">
                <v-text-field
                  v-model="bodyRequest.point"
                  placeholder="2.25"
                  :disabled="disableButton"
                  :rules="[v => !!v || $t('app.required')]"
                  type="number"
                  min="0"
                  max="100"
                  outlined
                  dense
                  required
                  @keyup="validatePoint"
                />
              </v-col>
              <v-col cols="12">
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="font-weight-bold">
                {{ $t("e_test.explain") }}
              </v-col>
              <v-col cols="12" sm="8">
                <v-textarea
                  v-model="bodyRequest.discussion"
                  :disabled="disableButton"
                  :placeholder="$t('app.write') + ' ' + $t('e_test.explain')"
                  auto-grow
                  dense
                  rows="1"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-divider class="my-3" />
              </v-col>
              <v-col cols="12" sm="4" class="font-weight-bold">
                {{ $t("e_test.subject_tags") }}
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox
                  v-model="tags"
                  :items="listTags"
                  :disabled="disableButton"
                  :loading="loadingTags"
                  chips
                  clearable
                  multiple
                  dense
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      text-color="primary"
                      color="white"
                      class="ma-1"
                      close
                      label
                      close-icon="mdi-close"
                      @click="select"
                      @click:close="removeTags(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-row no-gutters justify="end">
              <v-btn
                :disabled="disableButton"
                class="mr-2"
                outlined
                small
                color="primary"
                depressed
                @click="toggle(false)"
              >
                {{ $t("app.cancel") }}
              </v-btn>
              <v-btn
                :loading="loadingSave"
                small
                dark
                class="gradient-primary"
                depressed
                @click="save"
              >
                {{ $t("app.save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import {
  getClassEtest,
  getListEtest,
  submitQuestionEtest,
  setQuestionBankGlobal,
  updateQuestionBankGlobal
} from "@/api/admin/academic/eTest";
import {
  ImageExtend,
  QuillWatch,
  container
} from "@/plugins/quill-image-extend-module";
import { listCompetenciesByLessonDetail } from "@/api/admin/academic/basicCompetencies";
import { class_list } from "@/api/admin/academic/classList";
import { get_school_year_list, get_grade_list } from "@/api/admin/schoolClass";
import { getToken } from "@/utils/storage";
import { deleteFile, uploadFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";
import { quillEditor, Quill } from "vue-quill-editor";
import ImageResize from "quill-image-resize-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

Quill.register("modules/ImageExtend", ImageExtend);
Quill.register("modules/ImageResize", ImageResize);

import moment from "moment/moment";
moment.locale("id");

export default {
  props: {
    open: Boolean,
    info: Object,
    isGlobal: Boolean,
    subjects: Object,
    isEdit: Boolean,
    dataEdit: Object
  },
  watch: {
    isGlobal: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getGrade();
        }
      }
    },
    isEdit(value) {
      const grade = this.dataEdit.grade;
      if (value) {
        this.$nextTick(() => {
          this.bodyRequest = this.dataEdit;
          if (this.bodyRequest.type === "pg") {
            this.bodyRequest.esay = {
              key: "",
              key_file: []
            };
          } else {
            this.bodyRequest.pg = this.dataPg();
          }
          if (this.dataEdit.tags) {
            this.tags = this.dataEdit.tags?.split(",");
          }
        });
        if (grade) {
          const body = { grade_id: grade };
          this.getLesson({ body });
          this.listEtest({ body });
        }
      }
    },
    open(value) {
      if (value) {
        this.initialData();
        this.getClassEtestList(this.info?.etest);
        this.getCompetencies(this.info?.lesson ?? 0);
        this.getSchoolYear();
        if (this.info?.class) {
          this.listEtest({ class: this.info.class });
        }
        this.tempPathFile = [];
        this.questionFile.tempFile = [];
      }
    },
    "bodyRequest.lesson": {
      handler(value) {
        if (value) {
          this.getCompetencies(value);
        }
      }
    },
    "bodyRequest.grade": {
      handler(value) {
        const body = { grade_id: value };
        if (value) {
          this.getLesson(body);
          if (!this.isEdit && this.isGlobal) {
            this.listEtest(body);
          }
        }
      }
    }
  },
  created() {
    window.katex = katex;
  },
  components: {
    quillEditor,
    ButtonUpload: () => import("@/components/ButtonUpload")
  },
  data() {
    return {
      subjectsLoading: false,
      subjectsList: [],
      moduleFileName: "ETEST",
      extDocument:
        "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*",
      typeAnswer: [
        { id: "pg", name: this.$i18n.t("e_test.multiple_choice") },
        { id: "esay", name: "Essay" }
      ],
      listGrade: [],
      loadingGrade: false,
      listTags: [],
      loadingSave: false,
      disableButton: false,
      tempImages: [],
      editorOption: {
        modules: {
          ImageResize: {},
          toolbar: {
            container: container,
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id);
              }
            }
          },
          ImageExtend: {
            loading: true,
            name: "file",
            action: process.env.VUE_APP_BASE_API + "question_bank/upload_file",
            headers: xhr => {
              xhr.setRequestHeader("Authorization", "Bearer " + getToken());
              xhr.setRequestHeader("x-lang", this.$store.getters.g_language);
              xhr.setRequestHeader("x-institution", window.id_school);
            },
            response: res => {
              this.tempImages.push(res.data);
              return res.data;
            }
          }
        }
      },
      loadingClass: false,
      loadingKD: false,
      dataClass: [],
      dataKD: [],
      questionFile: {
        tempFile: [],
        loading: false
      },
      essayFile: {
        tempFile: [],
        loading: false
      },
      bodyRequest: this.getBody(),
      dataSchoolYear: [],
      loadingSchoolYer: false,
      loadingTags: false,
      tags: [],
      tempPathFile: []
    };
  },
  methods: {
    validatePoint(e) {
      const val = e.target.value;
      if (val) {
        if (val <= 0) {
          this.bodyRequest.point = 0;
        } else if (val >= 100) {
          this.bodyRequest.point = 100;
        } else {
          this.bodyRequest.point = val;
        }
      }
    },
    initialData() {
      this.$refs.addManual.reset();
      this.$refs.addManual.resetValidation();
      this.bodyRequest = this.getBody();
      this.tempImages = [];
      if (!this.isEdit) this.bodyRequest.type = "pg";
      // set year now
      this.bodyRequest.periode =
        this.info?.start_year ?? parseInt(moment().format("YYYY"));
      this.$nextTick(() => {
        if (!this.isEdit) {
          this.bodyRequest.question_file = [];
          this.bodyRequest.pg = this.dataPg(); // reset pilihan ganda
          this.bodyRequest.pg.map(d => (d.key = false));
        }
      });
    },
    getBody() {
      return {
        class: [],
        kd: 0,
        type: "pg",
        point: null,
        periode: 0,
        question: "",
        grade: "",
        question_file: [],
        discussion: "",
        lesson: 0,
        tags: [],
        esay: {
          key: "",
          key_file: []
        },
        pg: this.dataPg()
      };
    },
    dataPg() {
      return [
        {
          code: "A",
          answer: "",
          loading: false,
          answer_file: [],
          temp_image: [],
          key: false
        },
        {
          code: "B",
          answer: "",
          loading: false,
          answer_file: [],
          temp_image: [],
          key: false
        },
        {
          code: "C",
          answer: "",
          loading: false,
          answer_file: [],
          temp_image: [],
          key: false
        },
        {
          code: "D",
          answer: "",
          loading: false,
          answer_file: [],
          temp_image: [],
          key: false
        }
      ];
    },
    deleteAnswer(index) {
      this.bodyRequest.pg.splice(index, 1);
    },
    addedAnswer() {
      const totalPG = this.bodyRequest.pg.length;
      const arrayLetter = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K"
      ];
      let answer = {
        code: arrayLetter[totalPG],
        answer: "",
        loading: false,
        answer_file: [],
        temp_image: [],
        key: false
      };
      this.bodyRequest.pg.push(answer);
    },
    async getGrade() {
      this.loadingGrade = true;
      try {
        const response = await get_grade_list();
        if (response.data.code) {
          this.listGrade = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingGrade = false;
    },
    generateYears() {
      let currentYear = new Date().getFullYear();
      let years = [];
      let startYear = 2000;
      while (startYear <= currentYear) {
        let year = startYear++;
        years.push({ name: `${year}`, id: year });
      }
      return years.reverse();
    },
    removeTags(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    },
    async listEtest(body) {
      this.loadingTags = true;
      try {
        const response = await getListEtest(body);
        if (response.data.code === 1) {
          response.data.data.map(d => this.listTags.push(d.lesson_name));
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingTags = false;
    },
    async getLesson(body) {
      this.subjectsLoading = true;
      try {
        const response = await getListEtest(body);
        if (response.data.code) {
          this.subjectsList = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getLesson()", error);
        this.subjectsLoading = false;
      }
      this.subjectsLoading = false;
    },
    async save() {
      this.loadingSave = true;
      this.disableButton = true;
      if (this.$refs.addManual.validate()) {
        if (this.bodyRequest.question) {
          if (
            this.bodyRequest?.pg?.some(d => d.key === true) ||
            this.bodyRequest?.esay?.key
          ) {
            // validate minimal answer pg
            if (this.validateMinAnswerPG()) {
              // key answer not empty
              if (this.keyAnswerPgNotEmpty()) {
                // check answer pg duplicate
                if (!this.isSameAnswerPG()) {
                  this.bodyRequest.tags = this.tags.toString();
                  if (this.isGlobal) {
                    try {
                      const response = this.isEdit
                        ? await updateQuestionBankGlobal(this.bodyRequest)
                        : await setQuestionBankGlobal(this.bodyRequest);
                      if (response.data.code) {
                        const msg = this.isEdit
                          ? "msg_success_update_question_bank"
                          : "msg_success_create_question_bank";
                        this.snackBar(true, this.$i18n.t(`e_test.${msg}`));
                        this.toggle(true);
                      } else {
                        this.snackBar(false, response.data.message);
                      }
                    } catch (error) {
                      this.snackBar(false, error);
                      this.loadingSave = false;
                    }
                  } else {
                    try {
                      this.bodyRequest.etest = this.info.etest;
                      this.bodyRequest.etest_class = this.info.etest_class;
                      this.bodyRequest.grade = this.info.grade;
                      this.bodyRequest.lesson = this.info.lesson;
                      const response = await submitQuestionEtest(
                        this.bodyRequest
                      );
                      if (response.data.code) {
                        this.snackBar(
                          true,
                          this.$i18n.t(
                            "e_test.msg_success_create_question_bank"
                          )
                        );
                        this.toggle(true);
                      } else {
                        this.snackBar(false, response.data.message);
                      }
                    } catch (error) {
                      this.snackBar(false, error);
                      this.loadingSave = false;
                    }
                  }
                } else {
                  this.snackBar(false, this.$i18n.t("e_test.msg_same_answer"));
                }
              } else {
                this.snackBar(
                  false,
                  this.$i18n.t("e_test.msg_answer_cannot_empty")
                );
              }
            } else {
              this.snackBar(false, this.$i18n.t("e_test.msg_answer"));
            }
          } else {
            if (this.bodyRequest.type === "pg") {
              this.snackBar(false, this.$i18n.t("e_test.msg_answer_key"));
              this.scroll("#answerPg");
            } else {
              this.snackBar(false, this.$i18n.t("e_test.msg_answer_essay"));
              this.scroll("#answerEssay");
            }
          }
        } else {
          this.snackBar(false, this.$i18n.t("e_test.msg_question"));
          this.scroll("#question");
        }
      } else {
        this.$nextTick(() => {
          try {
            this.scroll(".v-messages.error--text");
          } catch (error) {
            console.error("save()", error);
            this.loadingSave = false;
          }
        });
      }
      this.loadingSave = false;
      this.disableButton = false;
    },
    validateMinAnswerPG() {
      if (this.bodyRequest.type === "pg") {
        return (
          this.bodyRequest?.pg?.filter(item =>
            item.answer_file.length != 0 || item.answer ? true : false
          ).length >= 2
        );
      } else {
        return true;
      }
    },
    isSameAnswerPG() {
      if (this.bodyRequest.type === "pg") {
        const answerList = this.bodyRequest.pg
          .filter(item => item.answer != "")
          .map(item => item.answer);
        return new Set(answerList).size !== answerList.length;
      } else {
        return false;
      }
    },
    keyAnswerPgNotEmpty() {
      if (this.bodyRequest.type === "pg") {
        let result = [];
        this.bodyRequest.pg
          .filter(answer => answer.key)
          .map(item => {
            if (item.answer_file.length != 0 || item.answer) {
              result.push(true);
            } else {
              result.push(false);
            }
          });
        return !result.includes(false);
      } else {
        return true;
      }
    },
    scroll(element) {
      try {
        document
          .querySelector(element)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      } catch (error) {
        console.error("scroll()", error);
        this.loadingSave = false;
      }
    },
    openLink: url => window.open(url, "_blank"),
    uploadQuestion(type, object) {
      let files;
      let path;
      let data;
      let request;

      switch (type) {
        case "question":
          files = this.questionFile.tempFile;
          path = "question_bank/upload_file";
          data = this.questionFile;
          request = this.bodyRequest.question_file;
          break;
        case "essay":
          files = this.essayFile.tempFile;
          path = "question_bank/answer/essay/upload_file";
          data = this.essayFile;
          request = this.bodyRequest.esay.key_file;
          break;
        case "pg":
          files = object.item.tempFile;
          path = "question_bank/answer/pg/upload_file";
          data = object.item;
          request = this.bodyRequest.pg[object.index].answer_file;
          break;
      }
      new Promise(async () => {
        if (files.length > 0 && files.length <= 3) {
          data.loading = true;
          await Promise.all(
            files.map(async file => {
              let body = new FormData();
              body.set("path", path);
              body.set("module", this.moduleFileName);
              body.append("file", file);

              const res = await uploadFile(body);
              request.push(res.data.data.path);
            })
          );
          data.loading = false;
        }
      });
    },
    async getSchoolYear() {
      this.loadingSchoolYer = true;
      try {
        const response = await get_school_year_list();
        if (response.data.code === 1) {
          response.data.data.map(d => {
            this.dataSchoolYear.push(d.start_year);
            this.dataSchoolYear.push(d.end_year);
          });
          [...new Set(this.dataSchoolYear)];
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingSchoolYer = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async removePgFile() {
      if (this.bodyRequest.pg.length) {
        await this.bodyRequest.pg.map(async answer => {
          answer.answer_file.map(async url => {
            const path = sliceAwsPath(url);
            await deleteFile(path, this.moduleFileName);
          });
        });
      }
    },
    async removeEsayFile() {
      if (this.bodyRequest.esay.key_file.length) {
        await this.bodyRequest.esay.key_file.map(async url => {
          const path = sliceAwsPath(url);
          await deleteFile(path, this.moduleFileName);
        });
      }
    },
    async removeQuestionFile() {
      if (this.bodyRequest.question_file) {
        await this.bodyRequest.question_file.map(async d => {
          const path = sliceAwsPath(d);
          await deleteFile(path, this.moduleFileName);
        });
      }
    },
    async removeTempImage() {
      if (this.tempImages.length) {
        await this.tempImages.map(async d => {
          const path = sliceAwsPath(d);
          await deleteFile(path, this.moduleFileName);
        });
      }
    },
    async removeTempPathFile() {
      if (this.tempPathFile.length) {
        await this.tempPathFile.map(async path => {
          await deleteFile(path, this.moduleFileName);
        });
      }
    },
    async removeFiles(array, item) {
      let split = item.split("/");
      split.splice(0, 4);
      array.splice(array.indexOf(item), 1);
      this.tempPathFile.push(split.join("/"));
    },
    async toggle(isSave) {
      if (isSave) {
        this.removeTempPathFile();
      } else {
        this.removeTempImage();
        if (!this.isEdit) {
          this.removeTempPathFile();
          this.removeEsayFile();
          this.removePgFile();
          this.removeQuestionFile();
        }
      }
      this.$refs.addManual.reset();
      this.bodyRequest.question = "";
      this.$emit("toggleDialog");
    },
    async getClassEtestList(etest) {
      if (etest) {
        this.loadingClass = true;
        try {
          const response = await getClassEtest({ etest: etest });
          if (response.data.code === 1) {
            response.data.data.map(d =>
              this.bodyRequest.class.push(d.class_id)
            );
            await this.getClass(this.info.school_year_master);
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
        this.loadingClass = false;
      }
    },
    async getClass(schoolYearMaster) {
      if (schoolYearMaster) {
        try {
          const response = await class_list({
            filter: {
              "classroom.school_year_master": [schoolYearMaster]
            }
          });
          if (response.data.code === 1) {
            this.dataClass = response.data.data.filter(item =>
              this.bodyRequest.class.includes(item.id)
            );
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      }
    },
    async getCompetencies(lesson) {
      if (lesson) {
        this.loadingKD = true;
        this.dataKD = [];
        try {
          const response = await listCompetenciesByLessonDetail({
            lesson: lesson
          });
          if (response.data.code === 1) {
            this.dataKD = response.data.data;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
        this.loadingKD = false;
      }
    }
  }
};
</script>
